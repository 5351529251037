@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: 'Poppins', sans-serif;
	box-sizing: border-box;
}

.arrowRight {
	top: 13px !important;
}

.text-theme {
	color: #f8c933;
}

.bg-theme {
	background: #f8c933;
}

.dropdown-menu {
	right: 0;
}
.app-image{
	& span{
		display:block !important;
		top: 7px !important;
	}
}
// .dropdown-menu a:hover {
// 	background-color: rgba(0, 0, 0, 0.8);
// 	color: #f8c933;
// }
.dropdown-submenu {
	right: -256px;
	top: 0;
}

.slider-section {
	&
	.card {
		min-height: 360px;
	}
}

.liveLocation span {
	vertical-align: middle;
}

.img-overly {
	&
	span {
		display: block !important;
	}
}
.img-overly:after,
.imgOverly {
	content: '';
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.bg-light {
	background: #fcfcfc;
}

.card-item span {
	display: block !important;
}
.image-default{
	& span{
		vertical-align: bottom;
	}
}
.footer {
	&
	h4 {
		display: flex;
		align-items: center;
		border-color: #f8c933;
		padding-bottom: 5px;
		justify-content: space-between;
	}
}

.bg-yellowlight {
	background: #fff9e4;
}
.bg-themeColor {
	background-color: #f9c933;
}
.text-themeColor {
	color: #f9c933;
}
.border-themeColor {
	border-color: #f9c933;
}
select:not(.footer select) {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
	padding-right: 2.5rem;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
}
.tabs .active {
	margin-bottom: -1px;
}
.sidebar-nav span {
	vertical-align: middle;
}
.sidebar-nav .active {
	background: #f9ca32;
}
.maxh-400{
	max-height: 400px;
}
.table-language::-webkit-scrollbar {
	width: 2px;
  }
  .large-dropdown .active a{
	  background: #F9C933;
  }
 .card-img{
	 &
	 span{
		 display: block !important;
	 }
 }
  .courser-list{
	&
	span{
		display: block !important;
	}
	  &
	  .bg-overly{
		background: rgba(0, 0, 0, 0.5);
	}
	&
	img{
		object-fit: cover;
	}
  }
  .slick-prev:before, .slick-next:before{
	  font-size: 40px !important;
  }
 
   .slick-center.slick-slide {
	   & >
	   div {
		-moz-transform: scale(1.05);
		-ms-transform: scale(1.05);
		-o-transform: scale(1.05);
		-webkit-transform: scale(1.05);
		opacity: 1;
		transform: scale(1.05);
		margin: 15px 0;
	}
   }
   .circleAnimation {
	-webkit-animation: circle 40s infinite alternate ease-in-out;
			animation: circle 40s infinite alternate ease-in-out;
  }
  
  @-webkit-keyframes circle {
	from {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
	to {
	  -webkit-transform: translate(400px);
			  transform: translate(400px);
	}
  }
  
  @keyframes circle {
	from {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
	to {
	  -webkit-transform: translate(400px);
			  transform: translate(400px);
	}
  }
  
  .circleAnimation2 {
	-webkit-animation: circle2 40s infinite alternate ease-in-out;
			animation: circle2 40s infinite alternate ease-in-out;
  }
  
  @-webkit-keyframes circle2 {
	from {
	  -webkit-transform: translateX(100px);
			  transform: translateX(100px);
	}
	to {
	  -webkit-transform: translateX(-400px);
			  transform: translateX(-400px);
	}
  }
  
  @keyframes circle2 {
	from {
	  -webkit-transform: translateX(100px);
			  transform: translateX(100px);
	}
	to {
	  -webkit-transform: translateX(-400px);
			  transform: translateX(-400px);
	}
  }
  
  .vectiorAnimation {
	-webkit-animation: vector 8s infinite alternate ease-in-out;
			animation: vector 8s infinite alternate ease-in-out;
  }
  
  @-webkit-keyframes vector {
	from {
	  -webkit-transform: rotate(10deg);
			  transform: rotate(10deg);
	}
	to {
	  -webkit-transform: translateX(20deg);
			  transform: translateX(20deg);
	}
  }
  
  @keyframes vector {
	from {
	  -webkit-transform: rotate(10deg);
			  transform: rotate(10deg);
	}
	to {
	  -webkit-transform: translateX(20deg);
			  transform: translateX(20deg);
	}
  }
  
  .vectiorAnimation2 {
	-webkit-animation: vector2 8s infinite alternate ease-in-out;
			animation: vector2 8s infinite alternate ease-in-out;
  }
  
  @-webkit-keyframes vector2 {
	from {
	  -webkit-transform: rotate(40deg);
			  transform: rotate(40deg);
	}
	to {
	  -webkit-transform: translateX(0deg);
			  transform: translateX(0deg);
	}
  }
  
  @keyframes vector2 {
	from {
	  -webkit-transform: rotate(40deg);
			  transform: rotate(40deg);
	}
	to {
	  -webkit-transform: translateX(0deg);
			  transform: translateX(0deg);
	}
  }
  .shadow-card{
	  box-shadow: 0 10px 40px -8px rgba(0, 0, 0, 0.1);
  }
//   .home-container{
// 	  background: url('../public/home-bg.png') no-repeat;
// 	  background-size:cover;
//   }
  .shadow-input{
	box-shadow: 0 0 39px -8px rgba(0,0,0,.10);
}
.custom-x-scroll::-webkit-scrollbar {
	width: 0;
  height: 0;
  }
  .custom-x-scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
	border-radius:10px;
}
  .custom-track::-webkit-scrollbar-track{
	background-color: darkgrey;
	border-radius:10px;
  }
  .bits-icon{
	  & span{
		  vertical-align:middle;
	  }
  }
  
  .category-img {
	&
	img, span{
	width:120px !important;
	height:120px !important;
	display:block !important;
}
}
.video-bg{
	& 
	.full-img{
		& 
		span{
			display:block !important;
			height:100% !important;
		}
		&
		img{
			width:100% !important;
			height: auto !important;
		}
	}
}
.course-container{
	&
	.slick-prev{
		left: 10px;
	}
	&
	.slick-next{
		right: 30px;
	}
}
/* Tablet (xs)  */
@media only screen and (min-width: 280px) {
	.innerDiv {
		padding-left: 30px;
		padding-right: 30px;
	}
	.slider-section {
		&
		.card img {
			width: 120px !important;
			height: 120px !important;
		}
	}
	header {
		&
		.innerDiv {
			padding-top: 2px;
			padding-bottom: 2px;
		}
	}
	.progress-circle {
		width: 130px !important;
		height: 130px !important;
		box-sizing: border-box;
	}
	.mobileLogo {
		max-width: 110px;
	}
}

/* Tablet (sm)  */
@media only screen and (min-width: 640px) {
	.innerDiv {
		padding-left: 60px;
		padding-right: 60px;
	}
	header {
		&
		.innerDiv {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.footerLink {
		display: block !important;
	}
	.subjectTab {
		display: flex !important;
	}
	.mobileLogo {
		max-width: 130px;
	}
	
	.slick-slide >div{
		opacity: 0.5;
	}
}

/* Tablet (md)  */
@media only screen and (min-width: 768px) {
	.innerDiv {
		padding-left: 60px;
		padding-right: 60px;
	}
	header {
		&
		.innerDiv {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.progress-circle {
		width: 170px !important;
		height: 170px !important;
		box-sizing: border-box;
	}
	.search-img {
		&
		img, span{
		width:200px !important;
		height:200px !important;
		display:block !important;
	}
	}
	.category-img {
		&
		img, span{
		width:200px !important;
		height:200px !important;
		display:block !important;
	}
	}
}

/* Desktop (lg) */
@media only screen and (min-width: 1024px) {
	.innerDiv {
		padding-left: 80px;
		padding-right: 80px;
	}
	.NavContainer {
		display: flex !important;
	}
	header {
		&
		.innerDiv {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.progress-circle {
		width: 160px !important;
		height: 160px !important;
		box-sizing: border-box;
	}
}

/* Desktop (xl)  */
@media only screen and (min-width: 1280px) {
	.innerDiv {
		max-width: calc(1280px + 160px);
		margin: 0 auto;
		padding-left: 80px;
		padding-right: 80px;
	}
	header {
		&
		.innerDiv {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.progress-circle {
		width: 160px !important;
		height: 160px !important;
		box-sizing: border-box;
	}
}

/* Desktop (2xl)  */
@media only screen and (min-width: 1536px) {
	.innerDiv {
		max-width: 1280px;
		margin: 0 auto;
		padding-left: 0;
		padding-right: 0;
	}
	header {
		&
		.innerDiv {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

/* Max Width 2560px */
main,
header,
footer {
	max-width: 2560px;
	margin: 0 auto;
}
.recommanded-course .swiper-button-prev,
.recommanded-course .swiper-button-next{
	background: #202020;
	height: 30px !important;
	width: 30px !important;
	border-radius: 50%;
	z-index: 10 !important;
	display:none;
	&::after {
		font-size: 16px !important;
		font-weight: 700 !important;
		color: #EDB300;
	}
	@media only screen and (min-width: 640px) {
		display: flex;
	}
}
.slider-section .swiper-button-prev,
.slider-section .swiper-button-next {
	background: #000;
	height: 50px !important;
	width: 50px !important;
	border-radius: 50%;
	z-index: 10 !important;
	&::after {
		font-size: 18px !important;
		font-weight: 700 !important;
		color: #fff9e4;
	}

	@media only screen and (min-width: 1024px) {
		display: none !important;
	}
}

.slider-section .swiper-button-prev {
	left: 0 !important;
	transform: translateX(-50%);
}

.slider-section .swiper-button-next {
	right: 0 !important;
	transform: translateX(50%);
}

// bottom header
@media (min-width: 786px) {
	.headroom {
		display: none;
	}
}
.headroom {
	top: auto !important;
	bottom: 0 !important;
	left: 0;
	right: 0;
	z-index: 1;
	background-color: white;
	border-top-width: 2px;
	border-color: rgb(226 232 240);
	z-index: 10;
	padding: 0.5rem 0;
}
.headroom--unfixed {
	position: relative;
	transform: translateY(0);
}
.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
	position: fixed;
	transform: translateY(100%) !important;
}
.headroom--pinned {
	position: fixed;
	transform: translateY(0%);
}
@media (max-width:640px) {
	.slick-next{
		right: 0 !important;
	}
	//  .home-container{
	// 	 background:none;
	//  }
	.subject-list .card-img {
		& 
		img
		{
			width: 100% !important;
		}
		&
		span{
			display: block !important;
		}
	}
	.course-container{
		&
		.slick-arrow{
			display:none;
		}
	}
	.story-img{
		&
		span{
			display:block !important;
		}
		& img{
			height: calc(100vh - 54px) !important;
			object-fit: cover;
			
		}
	}
	.content{
		min-height:150px;
		overflow-y:scroll;
	}
	.search-img {
		&
		img, span{
		width:90px !important;
		height:90px !important;
		display:block !important;
	}
	}
	.userProfile{
		& span{
			display: block !important;
		}
		& img{
			width: 50px !important;
			height: 50px !important;
		}
	}
}
.videoCover{
	&:after{
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
		position: absolute;
		inset: 0;
		content:"";
		z-index: 0;
	}
}
@media(max-width:1024px){
	.video-bg{
		&
		.full-img{
			& 
			img{
				width:100% !important;
				min-height: 500px !important;
				object-fit:cover;
				object-position: top;
			}
		}
		
	}
	.custom-x-scroll::-webkit-scrollbar {
	  width: 5px;
	  height: 8px;
	  }	
}
.videoPlayImg{
	&
	.full-img{
	&
	span{
		display:block !important;
	}
	&
	img{
		width:100% !important;
		height:100vh !important;
		object-fit:cover;
	}
}
}
.spinner-loader{
	display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -.125em;
    border: .25em dotted #000000;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-loader;
    animation: .75s linear infinite spinner-loader;
}
@-webkit-keyframes spinner-loader {
    to {
        transform: rotate(360deg)
    }
}
.bottom-nav{
	&
	span{
		display:block !important;
	}
}
.nonactive{
	&
	.showactive{
		display:none !important;
	}
	&
	.hidenonactive{
		display:block !important;
	}
}
.pageactive{
	&
	.showactive{
		display:block !important;
	}
	&
	.hidenonactive{
		display:none !important;
	}
}
.thread-player-container{
	&
	.swiper-horizontal>.swiper-scrollbar{
		bottom: auto;
		top: 3px;
		opacity: 1 !important;
	}
}
.desktop-icon{
	// border: 1px solid #f8c933;
	box-shadow:0 8px 20px -8px rgb(0 0 0 / 10%);
	//box-shadow: 0 0px 10px 2px rgb(0 0 0 / 5%);
	transform: translateX(-50%);
	filter: blur(5%);
	opacity: 1;
}
.desktop-icon{
	&
	span{
		display: block !important;
		&
		img{
			width: 20px !important;
			height: 20px !important;
		}
	}
	
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
	bottom: 0 !important;
}